<template>
  <b-overlay :variant="skin" :show="isloading" spinner-variant="primary" spinner-type="grow">
    <viewcard--c
      :btback="{
        style: 'margin-top: 44px;position: absolute;right: 26px; z-index: 999;',
      }"
      id="viewcardjobtype"
    >
      <validation-observer ref="jobstypeForm">
        <form-wizard
          :title="null"
          :subtitle="null"
          color="#1890ff"
          finish-button-text="Salvar"
          back-button-text="Voltar"
          next-button-text="Próximo"
          class="mb-3"
          @on-complete="validationForm"
          ref="formWiz"
        >
          <tab-content
            title="Informações gerais"
            icon="feather icon-file-text"
            :before-change="onHandleAccountDetails"
          >
            <div class="row mb-2">
              <div class="col-6">
                <b-form-group label="Nome *">
                  <validation-provider
                    #default="{ errors }"
                    name="Nome"
                    rules="required"
                  >
                    <b-form-input
                      v-model="record.name"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Nome"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                <b-form-group label="Tempo de preparação (minutos) * ">
                  <validation-provider
                    #default="{ errors }"
                    name="Tempo de preparação"
                    rules="required"
                  >
                    <b-form-input
                      :state="errors.length > 0 ? false : null"
                      v-model="record.preparation_time_minute"
                      type="number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                <b-form-group label="Quantidade de operadores necessários * ">
                  <validation-provider
                    #default="{ errors }"
                    name="Quantidade de operadores necessários"
                    rules="required"
                  >
                    <b-form-input
                      :state="errors.length > 0 ? false : null"
                      v-model="record.number_of_operators"
                      type="number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                <b-form-group label="Mínimo de estúdio * ">
                  <validation-provider
                    #default="{ errors }"
                    name="Mínimo de estúdio"
                    rules="required"
                  >
                    <b-form-input
                      :state="errors.length > 0 ? false : null"
                      v-model="record.min_studios"
                      type="number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <b-col cols="3" md="3" class="mt-1">
                <b-form-group label="CheckList Início *">
                  <validation-provider
                    #default="{ errors }"
                    name="CheckList Início"
                    rules="required"
                  >
                    <v-select
                      v-if="checklistStart.length > 0"
                      v-model="checklistStartSelected"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="checklistStart"
                      autocomplete="off"
                      :clearable="false"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="3" md="3" class="mt-1">
                <b-form-group label="CheckList Execução *">
                  <validation-provider
                    #default="{ errors }"
                    name="CheckList Execução"
                    rules="required"
                  >
                    <v-select
                      v-if="checklistExecution.length > 0"
                      v-model="checklistExecutionSelected"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="checklistExecution"
                      autocomplete="off"
                      :clearable="false"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="3" md="3" class="mt-1">
                <b-form-group label="CheckList Finalização *">
                  <validation-provider
                    #default="{ errors }"
                    name="CheckList Finalização"
                    rules="required"
                  >
                    <v-select
                      v-if="checklistFinish.length > 0"
                      v-model="checklistFinishSelected"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="checklistFinish"
                      autocomplete="off"
                      :clearable="false"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col class="mt-1" cols="12" md="12">
                <b-row>
                  <b-col cols="3">
                    <b-form-group label="Obrigatorio Upload Material">
                      <b-form-checkbox
                        class="custom-control-success"
                        name="check-button"
                        switch
                        v-model="record.required_upload_material"
                      >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <label class="mr-2 col-form-label">Disponível para </label>
                  </b-col>
                  <b-col cols="3">
                    <b-form-group label="Aulas">
                      <b-form-checkbox
                        class="custom-control-success"
                        name="check-button"
                        switch
                        v-model="record.available_for_lesson"
                      >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <b-col cols="3">
                    <b-form-group label="Eventos">
                      <b-form-checkbox
                        class="custom-control-success"
                        name="check-button"
                        switch
                        v-model="record.available_for_events"
                      >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </div>
            <div class="row">
              <b-col class="mt-1" cols="12" md="12">
                <b-row>
                  <b-col cols="12">
                    <label class="mr-2 col-form-label"
                      >Tipos de Produto *
                    </label>
                  </b-col>
                  <b-col cols="3" v-for="(prod, i) in products" :key="i">
                    <b-form-group :label="prod.name">
                      <b-form-checkbox
                        class="custom-control-success"
                        name="check-button"
                        switch
                        v-model="prod.selected"
                      >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col class="mt-1" cols="12" md="12">
                <b-row>
                  <b-col cols="12">
                    <label class="mr-2 col-form-label"
                      >Tipos de Formato *
                    </label>
                  </b-col>
                  <b-col cols="3" v-for="(form_, i) in formats" :key="i">
                    <b-form-group :label="form_.name">
                      <b-form-checkbox
                        class="custom-control-success"
                        name="check-button"
                        switch
                        v-model="form_.selected"
                      >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col class="mt-1" cols="12" md="12">
                <b-form-group label="Aceita material gravado ?">
                  <b-form-checkbox
                    class="custom-control-success"
                    name="check-button"
                    switch
                    v-model="record.accept_recordings"
                  >
                    <span class="switch-icon-left">
                      <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="XIcon" />
                    </span>
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col cols="3" md="3" class="mt-1">
                <b-form-group label="Período permitido *">
                  <validation-provider
                    #default="{ errors }"
                    name="Período permitido"
                    rules="required"
                  >
                    <v-select
                      v-if="periods.length > 0"
                      v-model="periodSelected"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="periods"
                      autocomplete="off"
                      :clearable="false"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="3" md="3" class="mt-1">
                <b-form-group label="Aceita extrapolação de horário">
                  <b-form-checkbox
                    class="custom-control-success"
                    name="check-button"
                    switch
                    v-model="record.accept_time_extrapolation"
                  >
                    <span class="switch-icon-left">
                      <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="XIcon" />
                    </span>
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </div>
            <div class="row">
              <b-col cols="3" md="3" class="mt-1">
                <b-form-group label="Tipos de transmissões aceitas *">
                  <v-select
                    v-if="types.length > 0"
                    v-model="typeObject.typeSelected"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="types"
                    autocomplete="off"
                    :clearable="false"
                    size="lg"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="2" md="2" class="mt-1">
                <b-form-group label="Quantidade">
                  <b-form-input
                    v-model="typeObject.quantity"
                    type="number"
                    size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="1" md="1" class="mt-3">
                <b-button
                  variant="info"
                  @click="addListType()"
                  :id="`adicionar-tipo`"
                  size="sm"
                >
                  <feather-icon icon="PlusIcon" />
                  <b-tooltip
                    triggers="hover"
                    :target="`adicionar-tipo`"
                    title="Adicionar Tipo"
                    :delay="{ show: 100, hide: 50 }"
                  />
                </b-button>
              </b-col>
            </div>
            <div class="row" v-if="itens_tabela.length > 0">
              <b-col cols="6" md="6">
                <b-table
                  :items="itens_tabela"
                  :fields="fields"
                  :busy="isloading"
                  responsive
                  hover
                  class="mt-1"
                >
                  <template #cell(actions)="data">
                    <div class="text-nowrap">
                      <feather-icon
                        @click="removeList(data.item)"
                        icon="TrashIcon"
                        class="mx-1"
                        size="22"
                      />
                    </div>
                  </template>
                </b-table>
              </b-col>
            </div>
          </tab-content>

          <tab-content
            title="Estúdios excluídos da demanda"
            icon="feather icon-headphones"
          >
            <div class="row">
              <b-col cols="4" md="4" class="mt-1">
                <b-form-group label="Selecione o grupo de estúdios">
                  <v-select
                    v-if="studioGroup.length > 0"
                    v-model="studioGroupSelected"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="studioGroup"
                    autocomplete="off"
                    :clearable="false"
                    @input="
                      (res) => onChangeStudioGroup(res, studioGroupSelected)
                    "
                  />
                </b-form-group>
              </b-col>
              <b-col class="mt-1" cols="12" md="12" v-if="studioGroupSelected">
                <b-row>
                  <b-col cols="12">
                    <label class="mr-2 col-form-label"
                      >Selecione abaixo os estúdios que a demanda não estará
                      disponível.
                    </label>
                  </b-col>
                  <b-col
                    cols="3"
                    class="mt-2"
                    v-for="(_studio, i) in studios"
                    :key="i"
                  >
                    <b-form-group :label="_studio.name">
                      <b-form-checkbox
                        class="custom-control-danger"
                        name="check-button"
                        switch
                        v-model="_studio.selected"
                      >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </div>
            <div class="row" v-if="studioGroupSelected">
              <b-col cols="1" md="1" class="mt-3">
                <b-button
                  variant="info"
                  @click="addStudioList()"
                  :id="`adicionar-studio`"
                  size="sm"
                >
                  <feather-icon icon="PlusIcon" />
                  <b-tooltip
                    triggers="hover"
                    :target="`adicionar-studio`"
                    title="Adicionar estúdio na lista dos excluídos"
                    :delay="{ show: 100, hide: 50 }"
                  />
                </b-button>
              </b-col>
            </div>
            <div class="row" v-if="itens_studios.length > 0">
              <b-col cols="8" md="8">
                <b-table
                  :items="itens_studios"
                  :fields="fields_studios"
                  :busy="isloading"
                  responsive
                  hover
                  class="mt-1"
                >
                  <template #cell(actions)="data">
                    <div class="text-nowrap">
                      <feather-icon
                        @click="removeStudioList(data.item)"
                        icon="TrashIcon"
                        class="mx-1"
                        size="22"
                      />
                    </div>
                  </template>
                </b-table>
              </b-col>
            </div>
          </tab-content>
        </form-wizard>
      </validation-observer>
    </viewcard--c>
  </b-overlay>
</template>
<script>
import _jobTypeService from "@/services/audiovisual/job-type-service";
import _productsService from "@/services/audiovisual/products-service";
import _jobtypeperiodService from "@/services/audiovisual/job-type-period-service";
import _transmissionTypeService from "@/services/audiovisual/transmission-type-service";
import _checklistService from "@/services/audiovisual/check-list-service";
import _studiosGroupService from "@/services/studios-group-service";
import _studioService from "@/services/studios-service";
import _formatTypeService from "@/services/audiovisual/format-type-service";
import useAppConfig from "@core/app-config/useAppConfig";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import pt_br from "vee-validate/dist/locale/pt_BR.json";
import { required } from "@validations";
import { BFormFile, BFormCheckboxGroup, BFormGroup } from "bootstrap-vue";

import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

export default {
  setup() {
    const { skin } = useAppConfig();
    return {
      skin,
    };
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormFile,
    BFormGroup,
    BFormCheckboxGroup,
    FormWizard,
    TabContent,
  },
  data() {
    return {
      loadingPopulate: true,
      record: {
        id: 0,
        name: "",
        preparation_time_minute: null,
        number_of_operators: null,
        available_for_events: false,
        available_for_lesson: false,
        accept_recordings: false,
        accept_time_extrapolation: false,
        required_upload_material: false,
      },
      typeObject: {
        typeSelected: null,
        quantity: 0,
      },
      products: [],

      periodSelected: null,
      periods: [],

      types: [],

      debounceMilliseconds: 500,
      isloading: true,

      itens_tabela: [],
      fields: [
        { key: "label", label: "Tipo" },
        { key: "quantity", label: "Quantidade" },
        { key: "actions", label: "Ações" },
      ],

      studioGroupSelected: null,
      studioGroup: [],

      studios: [],
      studiosSelected: null,

      checklist: [],
      checklistSelected: null,

      checklistStart: [],
      checklistStartSelected: null,

      checklistExecution: [],
      checklistExecutionSelected: null,

      checklistFinish: [],
      checklistFinishSelected: null,

      formats: [],
      itens_studios: [],
      fields_studios: [
        { key: "groupName", label: "Grupo" },
        { key: "name", label: "Estúdio" },
        { key: "actions", label: "Ações" },
      ],
    };
  },
  created() {
    localize("pt_BR", pt_br);
  },
  async mounted() {
    await this.populateOptions();
  },
  watch: {
    loadingPopulate(newLoading, oldLoading) {
      const hasPopulatedPage = newLoading === false && oldLoading === true
      if (hasPopulatedPage) {
        this.getRecord()
      }
    }
  },
  methods: {
    async populateOptions() {
      this.loadingPopulate = true
      await Promise.all([
        this.getProducts(),
        this.getPeriods(),
        this.getTransmissionTypes(),
        this.getStudioGroups(),
        this.getCheckList(),
        this.getFormats()
      ])
      this.loadingPopulate = false
    },
    getRecord() {
      if (this.$route.params.id > 0) {
        this.isloading = true;
        _jobTypeService
          .find(this.$route.params.id)
          .then((res) => {
            this.record = res.content.entity;

            if (this.record.jobTypePeriod) {
              this.periodSelected = {
                label: this.record.jobTypePeriod.name,
                value: this.record.jobTypePeriod.id,
              };
            }

            //Tipos de produto
            if (
              res.content.entity.jobProducts &&
              res.content.entity.jobProducts.length > 0
            ) {
              this.products.forEach(function (item) {
                res.content.entity.jobProducts.forEach(function (prod) {
                  if (item.id == prod.av_product_id) {
                    item.selected = true;
                  }
                });
              });
            }

            //Tipo de Transmissão
            if (
              this.record.jobTransmission &&
              this.record.jobTransmission.length > 0
            ) {
              this.itens_tabela = [];
              this.itens_tabela.push(
                ...this.record.jobTransmission.map((m) => {
                  return {
                    type: m.transmission_type_id,
                    label: m.transmissionType.name,
                    quantity: m.amount,
                  };
                })
              );
            }

            //Estudio
            if (
              res.content.entity.eventTypeStudios &&
              res.content.entity.eventTypeStudios.length > 0
            ) {
              this.itens_studios = [];
              this.itens_studios.push(
                ...res.content.entity.eventTypeStudios.map((m) => {
                  let place = m.studio.studiosGroup.place
                    ? `${m.studio.studiosGroup.place.name} -`
                    : "";

                  return {
                    id: m.studio_id,
                    name: m.studio.name,
                    groupName: `${place}${m.studio.studiosGroup.name}`,
                    groupId: m.studio.studiosGroup.id,
                  };
                })
              );
            }

            //Tipos de formatos
            if (
              res.content.entity.jobFormats &&
              res.content.entity.jobFormats.length > 0
            ) {
              this.formats.forEach(function (item) {
                res.content.entity.jobFormats.forEach(function (prod) {
                  if (item.id == prod.format_type_id) {
                    item.selected = true;
                  }
                });
              });
            }

            // Checklists Início / Execução / Finalização
            if (
              res.content.entity.checkListsJobType &&
              res.content.entity.checkListsJobType.length > 0
            ) {
              let start = res.content.entity.checkListsJobType.find(
                (f) => f.checkList.type === 0
              );
              let execution = res.content.entity.checkListsJobType.find(
                (f) => f.checkList.type === 1
              );
              let finish = res.content.entity.checkListsJobType.find(
                (f) => f.checkList.type === 2
              );

              if (start) this.checklistStartSelected = {
                value: start.checkList.id,
                label: start.checkList.name,
              };

              if (execution) this.checklistExecutionSelected = {
                value: execution.checkList.id,
                label: execution.checkList.name,
              };

              if (finish) this.checklistFinishSelected = {
                value: finish.checkList.id,
                label: finish.checkList.name,
              };
            }
          })
          .catch((error) => this.$utils.toastError("Notificação", error))
          .finally(() => this.isloading = false)
      } else {
        this.isloading = false
      }
    },
    validationForm() {
      this.$refs.jobstypeForm.validate().then((success) => {
        if (success) {
          this.save();
        }
      });
    },
    async getProducts() {
      await _productsService
        .showProductsAll()
        .then((res) => {
          this.products = res.content.map((m) => {
            return {
              name: `${m.name}`,
              id: m.id,
              selected: false,
            };
          });
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
    },
    async getPeriods() {
      await _jobtypeperiodService
        .showProductsAll()
        .then((res) => {
          this.periods = this.$utils.populardropdown(
            res.content,
            "name",
            "id",
            false,
            false
          );
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
    },
    async getTransmissionTypes() {
      await _transmissionTypeService
        .showProductsAll()
        .then((res) => {
          this.types = this.$utils.populardropdown(
            res.content,
            "name",
            "id",
            false,
            false
          );
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
    },
    addListType() {
      if (this.typeObject.typeSelected) {
        let limpar = false;
        let type_id = this.$utils.getValueSelected(
          this.typeObject.typeSelected
        );

        const type_selected = this.itens_tabela.filter(
          (f) => f.type === type_id
        );
        if (type_selected.length > 0) {
          limpar = true;
          this.$utils.toastError("Notificação", "Produto já adicionado.");
        } else {
          if (this.typeObject.quantity == 0) {
            this.$utils.toastError(
              "Notificação",
              "Informe a quantidade do tipo de transmissão."
            );
          } else {
            limpar = true;
            this.itens_tabela.push({
              type: type_id,
              label: this.typeObject.typeSelected.label,
              quantity: this.typeObject.quantity,
            });
          }
        }
        if (limpar) {
          this.typeObject.quantity = 0;
          this.typeObject.typeSelected = null;
        }
      }
    },
    async getStudioGroups() {
      await _studiosGroupService
        .showAll()
        .then((res) => {
          this.studioGroup.push(
            ...res.content.map((m) => {
              let place = m.place ? `${m.place.name} -` : "";
              return {
                label: `${place}${m.name}`,
                value: m.id,
              };
            })
          );
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
    },
    async getCheckList() {
      await _checklistService
        .showAll()
        .then((res) => {
          this.checklistStart.push(
            ...res.content
              .filter((f) => f.type === 0)
              .map((m) => {
                return {
                  label: `${m.name}`,
                  value: m.id,
                  type: m.type,
                };
              })
          );

          this.checklistExecution.push(
            ...res.content
              .filter((f) => f.type === 1)
              .map((m) => {
                return {
                  label: `${m.name}`,
                  value: m.id,
                  type: m.type,
                };
              })
          );

          this.checklistFinish.push(
            ...res.content
              .filter((f) => f.type === 2)
              .map((m) => {
                return {
                  label: `${m.name}`,
                  value: m.id,
                  type: m.type,
                };
              })
          );
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
    },
    onChangeStudioGroup(_selected, _studioGroup) {
      this.studios = null;
      _studioService
        .show(_studioGroup.value)
        .then((res) => {
          this.studios = res.content.map((m) => {
            return {
              name: `${m.name}`,
              id: m.id,
              selected: false,
            };
          });
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
    },
    removeList(item) {
      const new_list = this.itens_tabela.filter((f) => f.type !== item.type);
      this.itens_tabela = new_list;
    },
    async onHandleAccountDetails() {
      let result = await this.$refs.jobstypeForm.validate().then((success) => {
        if (success) {
          return true;
        }
        this.$utils.toastWarning(
          "Validação",
          "Por favor, preencha todos os campos obrigatórios."
        );
        return false;
      });
      return result;
    },
    save() {
      const payload = {
        data: {
          ...this.record,
          checkListsJobType: [
            {
              checklist_id: this.$utils.getValueSelected(
                this.checklistStartSelected
              ),
            },
            {
              checklist_id: this.$utils.getValueSelected(
                this.checklistExecutionSelected
              ),
            },
            {
              checklist_id: this.$utils.getValueSelected(
                this.checklistFinishSelected
              ),
            },
          ],
          av_job_period_id: this.$utils.getValueSelected(this.periodSelected),
          //checklist_id: this.$utils.getValueSelected(this.checklistSelected),
        },
      };
      payload.data.jobProducts = [];

      this.products.forEach((element) => {
        if (element.selected) {
          payload.data.jobProducts.push({
            av_product_id: element.id,
          });
        }
      });

      payload.data.jobTransmission = [];
      if (this.itens_tabela) {
        this.itens_tabela.forEach((element) => {
          payload.data.jobTransmission.push({
            transmission_type_id: element.type,
            amount: element.quantity,
          });
        });
      }

      payload.data.eventTypeStudios = [];
      if (this.itens_studios) {
        this.itens_studios.forEach((element) => {
          payload.data.eventTypeStudios.push({
            studio_id: element.id,
          });
        });
      }

      payload.data.jobFormats = [];
      this.formats.forEach((element) => {
        if (element.selected) {
          payload.data.jobFormats.push({
            format_type_id: element.id,
          });
        }
      });

      let comErro = false;
      if (payload.data.jobTransmission.length == 0) {
        comErro = true;
        this.$refs.formWiz.prevTab();
        this.$utils.toastError(
          "Notificação",
          "Necessário informar pelo menos um Tipo de Transmissão."
        );
      } else if (payload.data.jobProducts == 0) {
        comErro = true;
        this.$refs.formWiz.prevTab();
        this.$utils.toastError(
          "Notificação",
          "Necessário informar pelo menos um Tipo de produto."
        );
      }

      if (!comErro) {
        const _createOrUpdate =
          this.record.id <= 0
            ? _jobTypeService.create(payload)
            : _jobTypeService.update(payload);

        _createOrUpdate
          .then(() => {
            this.$utils.toast("Notificação", "Salvo com sucesso.");
            this.$router.go(-1);
          })
          .catch((error) => this.$utils.toastError("Notificação", error))
      }
    },
    async getFormats() {
      await _formatTypeService
        .showProductsAll()
        .then((res) => {
          this.formats = res.content.map((m) => {
            return {
              name: `${m.name}`,
              id: m.id,
              selected: false,
            };
          });
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
    },
    removeStudioList(item) {
      const new_list = this.itens_studios.filter((f) => f.id !== item.id);
      this.itens_studios = new_list;
    },
    addStudioList() {
      if (this.studioGroupSelected) {
        const studios_selecionados = this.studios.filter((f) => f.selected);

        if (studios_selecionados && studios_selecionados.length == 0) {
          this.$utils.toastError(
            "Notificação",
            "Necessário selecionar pelo menos um estúdio."
          );
        } else {
          let limpar = false;
          this.studios.forEach((element) => {
            if (element.selected) {
              const ja_adicionado = this.itens_studios.filter(
                (f) => f.id === element.id
              );
              if (ja_adicionado.length > 0) {
                element.selected = false;
                this.$utils.toastError(
                  "Notificação",
                  `Estúdio ${element.name} já adicionado.`
                );
              } else {
                this.itens_studios.push({
                  id: element.id,
                  name: element.name,
                  groupName: this.studioGroupSelected.label,
                  groupId: this.studioGroupSelected.value,
                });
                limpar = true;
              }
            }
          });

          if (limpar) {
            this.studioGroupSelected = null;
            this.studios = null;
          }
        }
      }
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";

#viewcardjobtype .card {
  background-color: transparent;
  box-shadow: initial;
}
</style>