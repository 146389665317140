import HttpService from '../HttpService'

const _http = new HttpService()
export default {
  show: (page, options = null) => {
    let _parans = `?page=${page - 1}`;
    if (options) {
      if(options.search) {
        _parans += `&search=${options.search}`;
      }
      if (options.orderByKey) {
        _parans += `&order_by=${options.orderByKey}`
      }
      if (options.ascOrDes) {
        _parans += '&asc'
      }
    }
    return _http.get(`/api/audio-visual/check-list${_parans}`)
  },
  find: (id) => {
    return _http.get(`/api/audio-visual/check-list/${id}`)
  },
  create: (payload) => {
    return _http.post('/api/audio-visual/check-list', payload)
  },
  update: (payload) => {
    return _http.put('/api/audio-visual/check-list', payload)
  },
  delete: (id) => {
    return _http.delete(`/api/audio-visual/check-list/${id}`)
  },
  showAll: () => {
    return _http.get(`/api/audio-visual/check-list/check-list-all`)
  },
  saveAnswers: (payload) => {
    return _http.post('/api/audio-visual/check-list/save-answers', payload)
  },
  saveAnswersCT: (payload) => {
    return _http.post('/api/audio-visual/check-list/save-answers-ct', payload)
  },
  findChecklist: (params) => {    
    let _params = `?data.appointment_studios_operator_id=${params.appointment_studios_operator_id}&data.type=${params.checkList.type}`;

     return _http.get(`/api/audio-visual/check-list/appointment${_params}`)
  },
  findChecklistCT: (params) => {    
    let _params = `?data.workshift_id=${params.workshift_id}&data.type=${params.type}&data.date=${params.date}`;

     return _http.get(`/api/audio-visual/check-list/central-tech${_params}`)
  },
  showAllTypes: () => {
    return _http.get(`/api/audio-visual/check-list/all-types`)
  },
}