import HttpService from './HttpService'

const _http = new HttpService()
export default {
  show: (page, options = null) => {
    let _parans = `?page=${page - 1}`;
    if (options) {
      if (options.search) {
        _parans += `&search=${options.search}`;
      }
      if (options.orderByKey) {
        _parans += `&order_by=${options.orderByKey}`
      }
      if (options.size) {
        _parans += `&size=${options.size}`
      }
      if (options.ascOrDes) {
        _parans += '&asc'
      }
    }
    return _http.get(`/api/studiosgroup${_parans}`)
  },
  find: (id) => {
    return _http.get(`/api/studiosgroup/${id}`)
  },
  create: (payload) => {
    return _http.post('/api/studiosgroup', payload)
  },
  update: (payload) => {
    return _http.put('/api/studiosgroup', payload)
  },
  delete: (id) => {
    return _http.delete(`/api/studiosgroup/${id}`)
  },
  showAll: (search = "") => {
    return _http.get(`/api/studiosgroup/auto-complete?search=${search}&asc`)
  },
}